<template>
  <div class="pages">
    <div class="page">
      <div class="inside-nav">
        <el-tree
          :data="treeData"
          ref="tree"
          show-checkbox
          node-key="id"
          :props="defaultProps"
          @check-change="handleCheckChange"
        >
        </el-tree>
      </div>

      <div class="task_box">
        <div class="out">
          <div class="btns" @click="exportlist">
            <div class="el-icon el-icon-upload2"></div>
            <div>导&nbsp;出</div>
          </div>
        </div>
        <!-- select 筛选 -->
        <div class="select_box"></div>
        <div class="oper_box">
          <div class="s_item s_item_s">
            <div class="s_lable">数据周期：</div>
            <el-select v-model="selectValue" placeholder="请选择设备名称" @change="changeTime">
              <el-option
                v-for="item in numoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              >
              </el-option>
            </el-select>
          </div>
          <div class="s_item s_item_s">
            <div class="s_lable">查询日期：</div>
            <el-date-picker v-model="screen" type="date" @change="timeChange(screen)" placeholder="选择日期" >
            </el-date-picker>
          </div>
          <div class="btns" @click="submit">查询</div>
        </div>
        <div class="table">
          <table>
            <tr>
              <th>设备名称</th>
              <th v-for="(item, index) in date" :key="index">{{item}}</th>
            </tr>
            <tr v-for="(item, index) in datelist">
              <td>{{item.name}}</td>
              <th v-for="ite in item.value">{{ite}}</th>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
       numoptions: [
        {
          value: "days",
          label: "日",
        },{
          value: "month",
          label: "月",
        },{
          value: "year",
          label: "年",
        },
      ],
      selectValue: "",
      // 副 菜单
      radio: 1,
      // 树状图选择
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      // 参数
      cycle: '',
      device_id: '',
      screen: '',
      // 
      datelist: [],
      date: []
    };
  },
  mounted: function () {
    this.getzuzhiList()
  },
  methods: {
    // 导出
    exportlist() {
        if (this.device_id == '') {
            this.$message.error('请选择组织');
          return
        }
        if (this.screen == '') {
          this.$message.error('请选择时间');
          return
        }
        if (this.cycle == '') {
          this.$message.error('请选择周期');
          return
        }
        window.location.href='http://211.149.176.35:9001/api/device/export_energy_usage?device_id='+this.device_id+'&screen='+this.screen+'&cycle='+this.cycle
      },
     async submit(){
     const res = await this.$http.post('device/energy_usage_report', {
        device_id: this.device_id,
        cycle: this.cycle,
        screen:this.screen,
      }
     )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.date = res.data.date
      this.datelist = res.data.list
    },
  // 周期下拉
      changeTime(e){
        this.cycle = e
      },
      // 时间选择
      timeChange (val) {
      const newDate = val.getFullYear() + '-' + (val.getMonth() + 1) + '-' + val.getDate();
      this.screen = newDate;
    },

        // 组织 获取
     async getzuzhiList() {
      const res = await this.$http.post('Organize/left')
      this.treeData = res.data
    },

    //获取选中的点
    handleCheckChange() {
      let res = this.$refs.tree.getCheckedNodes()
      let arr = []
      res.forEach((item) => {
        if (!item.children) {
          arr.push(item.id)
        }
      })
      this.device_id = arr.join(',');
    },

    handleNodeClick(data) {
      console.log(data);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

// 副菜单
  .inside-nav {
    width: 200px;
    height: calc(100% - 40px);
    margin-top: 20px;
    box-sizing: border-box;
    border: #61CDF1 2px solid;
    background-color: #1B284D;
    overflow-y: auto;
  }
  /* 左侧二级菜单滚动条 设置滚动条 */
  .inside-nav::-webkit-scrollbar {
    width: 4px;
  }
  .inside-nav::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #68D8FE;
  }
  .inside-nav::-webkit-scrollbar-track {
    border-radius: 0;
    background: #1B284D;
  }
  /* 设置滚动条  end */

  .el-tree {
    background-color: #1B284D;
    color: #ffffff;
    // overflow-x: auto;
  }
  ::v-deep .el-tree-node__label {
    font-size: 14px;
  }
  ::v-deep .el-tree-node__content {
    height: 30px;
  }
  ::v-deep .el-tree-node:focus>.el-tree-node__content {
    background-color: #1B284D;
    color: #68D8FE;
  }
  ::v-deep .el-tree-node>.el-tree-node__children{
    overflow: visible;
  }
  ::v-deep .el-tree-node__content:hover,  ::v-deep .el-upload-list__item:hover {
    background-color: #1B284D;
    color: #68D8FE;
  }
.out {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btns {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    margin-left: 25px;
    background-color: #68d8fe;
    border-radius: 5px;
    width: 70px;

    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    cursor: pointer;
    .el-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}
// 表格
.task_box {
  width: calc(100% - 220px);
  ::v-deep .el-input__inner {
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #e5e5e5 !important;
    border: none;
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #61cdf1 !important;
  }
  // charts
  .onecharts {
    width: 100%;
    height: 300px;
  }
  .twocharts {
    width: 100%;
    height: 300px;
  }
  .chartsTitle {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }

  // 顶部按钮
  .elmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-menu.el-menu--horizontal {
      border: 1px solid #61cdf1 !important;
    }
    .el-menu.el-menu--horizontal {
      border: none !important;
    }
    .el-menu {
      background-color: rgba(0, 0, 0, 0) !important;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-menu--horizontal > .el-menu-item {
      color: #fff !important;
    }
    .el-menu-item.is-active {
      color: #68d8fe !important;
    }

    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .el-menu-item {
      border-left: 0px;
      margin-right: 20px;
    }
  }

  .oper_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    margin-bottom: 20px;

    .btns {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      margin-left: 25px;
      background-color: #68d8fe;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      cursor: pointer;
      .el-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
    .s_item {
      display: flex;
      align-items: center;
      margin-left: 80px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }

  // select 筛选
  .index_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-tag {
      margin-right: 10px;
    }
  }
  .select_box {
    display: flex;
    align-items: center;
    padding: 15px;
    .s_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .btns {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #68d8fe;
      font-size: 16px;
      color: #ffffff;
      width: 80px;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 20px;
    }
  }
}
.s_item_s {
  margin-right: 30px;
  margin-left: 0px !important;
}
.table {
  margin-top: 30px;
  height: 750px;
    overflow-y: auto;
  table,
  th,
  td {
    border: 1px solid #61cdf1;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e5e5e5;
    border-collapse: collapse;
  }
  table {
    width: 100%;

    border: 2px solid #61cdf1;
  }
  th {
    height: 50px;
    width: 96px;
  }
  td {
    text-align: center;
    width: 180px;
    height: 50px;
  }
}
</style>